<script setup>
import * as Sentry from '@sentry/browser'
import { useTheme } from 'vuetify'
import ScrollToTop from '@core/components/ScrollToTop.vue'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import { useStore } from "@/stores"
import { EventSourcePolyfill } from 'event-source-polyfill'
import axios from '@axios'
import Cookies from 'js-cookie'

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
  handleSkinChanges,
} = useThemeConfig()

const { global } = useTheme()
const store = useStore()
const emitter = inject('emitter')

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
handleSkinChanges()

let msgServer
const isSnackbarVisible = ref(false)
const progress = ref('')
const uuid = ref(Cookies.get('uuid'))

onMounted(() => {
  if (store.isUserLoggedIn) {
    Sentry.setUser({ id: store.userData?.id })
    if (! uuid.value) {
      uuid.value = (new Date()).getTime()
      Cookies.set('uuid', uuid.value, { expires: 1 })
    }
    if (!store.userData) {
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      setTimeout(() => {
        window.location = '/login'
      }, 300)
    } else {
      store.getUserData(0)
    }
  }
  if (store.mercureToken) {
    subscribeEvent()
  } else {
    getMercureToken()
  }
})

async function getMercureToken() {
  const { data } = await axios.get('token')

  console.log('data.token', data.token)
  if (data.token) {
    store.updateMercureToken(data.token)
    subscribeEvent()
  }
}

function subscribeEvent() {
  const topics = [
    'ping',
    'pdf-generated',
  ]

  const query = topics.join('&topic=')

  msgServer = new EventSourcePolyfill(`${import.meta.env.VITE_MERCURE_URL}?topic=${query}`, {
    headers: {
      Authorization: `Bearer ${store.mercureToken}`,
    },
  })

  msgServer.addEventListener('message', message => {
    try {
      const { data } = message
      if (data) {
        const json = JSON.parse(data)

        if (json.event.match(/pdf-generated/)) {
          if (uuid.value == json.data.uuid) {
            if (json.data.zip_url) {
              window.location = json.data.zip_url
            } else if(json.data.progress) {
              progress.value = json.data.progress
              isSnackbarVisible.value = true
            }
          }
        } else {
          emitter.emit(json.event, json.data)
        }
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log('Error:', ex)
    }
  })
}
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <ScrollToTop />
      <VSnackbar v-model="isSnackbarVisible">
        Generating PDF ({{ progress }})
      </VSnackbar>
    </VApp>
  </VLocaleProvider>
</template>
