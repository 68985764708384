/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import { abilitiesPlugin } from '@casl/vue'
import ability from '@/plugins/casl/ability'
import i18n from '@/plugins/i18n'
import Swal from 'sweetalert2'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueEasyLightbox from 'vue-easy-lightbox'
import * as Sentry from '@sentry/vue'
import emitter from '@/plugins/event'

import '@core/scss/template/index.scss'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import 'sweetalert2/src/sweetalert2.scss'

// datepicker
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

loadFonts()

// Import apexcharts
import VueApexCharts from "vue3-apexcharts";

// Create vue app
const app = createApp(App)


// Use plugins
app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(layoutsPlugin)
app.use(i18n)
app.use(VueEasyLightbox)
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})
app.component(VueCountdown.name, VueCountdown)
app.provide('$swal', Swal)
app.provide('emitter', emitter)
app.component('VueDatePicker', VueDatePicker)

app.use(VueApexCharts)

Sentry.init({
  app,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  maxBreadcrumbs: 50,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: import.meta.env.VITE_SENTRY_RATE || 1.0,
})

// Mount vue app
app.mount('#app')
