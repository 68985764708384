<template>
  <div>
    <VCard>
      <VCardText>
        <div v-html="welcome"></div>
      </VCardText>
    </VCard>
  </div>
</template>

<script setup>
import axios from "@axios"
import { useStore } from "@/stores"

const store = useStore()
const welcome = ref('')
function getItems() {
  axios.get('dashboard')
    .then(({ data }) => {
      welcome.value = data.data
    })
    .catch(error => {
      console.log(error)
    })
}
onMounted(() => {
  getItems()
})
</script>

<route lang="yaml">
meta:
  action: view_dashboard
</route>
